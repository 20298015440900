<template>
  <div
  @mouseover="showDropdown"
  @mouseout="hideDropdown"
  class="single-select"
  >
    <div class="label"
      @click="displayGrade">
      <span>{{ label }}</span>
      <img src="@/assets/arrow-down.png"/>
    </div>
    <div
      v-show="displayDropdown"
      class="checkbox">
      <div v-for="option in options" :key="option.value" class="option">
        <label :for="id+`_check_`+option.value">{{ option.label }}</label>
        <input type="radio"
          @input="inputHandler"
          :name="id"
          v-model="selectedValues"
          :value="option.value"
          :id="id+`_check_`+option.value" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    default: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      selectedValues: this.value,
      displayDropdown: false,
    }
  },
  mounted() {

  },
  methods: {
    inputHandler(e) {
      // ALLが選択された
      if (e.target.value == 0 && e.target.checked) {
        this.selectAll()
      }
      // ALLが選択外れた
      if (e.target.value == 0 && !e.target.checked) {
        this.selectedValues = []
      }
      // ALL以外選択外れた
      if (e.target.value != 0 && !e.target.checked) {
        this.selectedValues = this.selectedValues.filter(v => v != 0 && v != e.target.value)
      }
    },
    selectAll() {
      const allValues = this.options.map( o => { return o.value })
      this.selectedValues = allValues
    },
    showDropdown() {
      this.displayDropdown = true
    },
    hideDropdown() {
      this.displayDropdown = false
    }
  },
  watch: {
    selectedValues(newVal) {
      this.$emit('changed', newVal)
    },
    options(newVal) {
      console.log('options changed:' + newVal)
      if (this.selectedValues.find(e => e == 0) != undefined) this.selectAll()
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  box-shadow: 0px 1px 2px #c9c4c485;
  border: 1px solid #d8dae2;
  border-radius: 5px;
  width: 130px;
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  text-indent: 10px;
  img {
    height: 30px;
  }
}
.checkbox {
  box-shadow: 0px 3px 6px #00000085;
  border: 1px solid #d8dae2;
  border-radius: 10px;
  display: inline-block;
  background: white;
  position: absolute;
  width: 130px;
  margin-top: 34px;
  padding: 10px 0px;
  font-size: 14px;
  max-height: 200px;
  overflow-y: scroll;

  .option {
    display: flex;
    padding: 0 3px 0 15px;
    align-items: center;

    label {
      flex: 5;
      text-align: left;
      cursor: pointer;
    }
    input {
      padding: 1px 0;
      flex: 2;
    }
  }
}
</style>