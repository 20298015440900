<template>
  <div
  @mouseover="showDropdown"
  @mouseout="hideDropdown"
  class="multi-select"
  >
    <div class="label"
      @click="displayGrade">
      <span>期間</span>
      <img src="@/assets/arrow-down.png"/>
    </div>
    <div
      v-show="displayDropdown"
      class="dropdown">
      <div>
        表示期間を選択してください
      </div>
      <div>
        <span
          @click="today"
          class="preset-range">
          本日
        </span>
        <span
          @click="yesterday"
          class="preset-range">
          昨日
        </span>
        <span
          @click="lastdays(7)"
          class="preset-range">
          過去7日
        </span>
        <span
          @click="lastdays(30)"
          class="preset-range">
          過去30日
        </span>
        <span
          @click="lastdays(90)"
          class="preset-range">
          過去90日
        </span>
        <span
          @click="thisYear"
          class="preset-range">
          今年
        </span>
      </div>
      <v-date-picker
        :locale="{ id: 'en',masks: { weekdays: 'WW' } }"
        :columns="2"
        v-model="range"
        :is-range="true"
        ref="calendar"
        mode="date" />
    </div>
  </div>
</template>

<script>
import 'v-calendar/dist/style.css'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedValues: this.value,
      displayDropdown: false,
      range: {
        start: new Date(),
        end: new Date(),
      },
    }
  },
  mounted() {
    this.lastdays(7)
    this.$emit('initialized', this.range)
  },
  methods: {
    showDropdown() {
      this.displayDropdown = true
    },
    hideDropdown() {
      this.displayDropdown = false
    },
    today() {
      console.log('today')
      this.range = {
        start: new Date(),
        end: new Date()
      }
      const calendar = this.$refs.calendar
      calendar.move(new Date())
    },
    yesterday() {
      const today = new Date()
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
      this.range = {
        start: yesterday,
        end: yesterday
      }
      const calendar = this.$refs.calendar
      calendar.move(yesterday)
    },
    lastweek() {
      const today = new Date()
      const lastSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7)
      const lastMonday = new Date(lastSunday.getFullYear(), lastSunday.getMonth(), lastSunday.getDate() + 6)
      this.range = {
        start: lastSunday,
        end: lastMonday
      }
      const calendar = this.$refs.calendar
      calendar.move(lastSunday)
    },
    lastdays(daysbefore) {
      const today = new Date()
      const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - daysbefore + 1)
      this.range = {
        start: start,
        end: today
      }
      const calendar = this.$refs.calendar
      calendar.move(today)
    },
    thisYear() {
      const today = new Date()
      const startDay = new Date(today.getFullYear(), 0, 1)
      this.range = {
        start: startDay,
        end: today
      }
      const calendar = this.$refs.calendar
      calendar.move(today)
    },
    setRange(start, end) {
      this.range = {
        start: new Date(start),
        end: new Date(end),
      }
      const calendar = this.$refs.calendar
      calendar.move(this.range.start)
    }
  },
  watch: {
    range(newVal) {
      this.$emit('changed', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  box-shadow: 0px 1px 2px #c9c4c485;
  border: 1px solid #d8dae2;
  border-radius: 5px;
  width: 130px;
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  text-indent: 10px;
  img {
    height: 30px;
  }
}
.dropdown {
  box-shadow: 0px 3px 6px #00000085;
  border: 1px solid #d8dae2;
  border-radius: 10px;
  display: inline-block;
  background: white;
  position: absolute;
  width: 550px;
  margin-left: -486px;
  padding: 10px 0px;
  font-size: 14px;
  height: 310px;
  overflow-y: scroll;

  .option {
    display: flex;
    padding: 0 3px 0 15px;
    align-items: center;

    label {
      flex: 5;
      text-align: left;
      cursor: pointer;
    }
    input {
      padding: 1px 0;
      flex: 2;
    }
  }
}

.range-calendar {
  border: 1px solid;
  margin: auto;
  width: 600px;
  padding: 10px;
  margin-top: 20px;
}

.vc-container {
  border: none;
}

.preset-range {
  color: blue;
  text-decoration: underline;
  margin: 0 5px;
  cursor: pointer;
}
</style>