<template>
  <div class="navi">
    <div class="menus">
    </div>
    <div class="title">
      学習状況レポート
    </div>
    <div class="filters">
      <div class="filter-line">
        <report-calendar
          class="filter student-filter"
          @initialized="initialized"
          ref="calendar"
          id="calendar"
          @changed="periodChanged"/>
      </div>
      <div class="operations mt-2">
        <button
          @click="updateCharts"
          class="btn-update">
          グラフ更新
        </button>
      </div>
    </div>
  </div>
  <div class="select-info">
    <div class="range">
      <span class="label">{{ selectedRange.start }}</span>
      〜
      <span class="label">{{ selectedRange.end }}</span>
    </div>
  </div>
  <div class="subtitle">
    <span class="text">個人レポート</span>
    <span class="filters">
      <multi-select
          v-if="stepOptions.length > 1"
          :options="stepOptions"
          class="filter"
          label="Step"
          id="step"
          :default="0"
          @changed="stepChanged"/>
        <multi-select
          v-if="formOptions.length > 1"
          :options="formOptions"
          class="filter"
          label="Form"
          id="form"
          :default="0"
          @changed="formChanged"/>
        <multi-select
          :options="lessonOptions"
          class="filter lesson"
          label="Lesson"
          id="lesson"
          :default="0"
          @changed="lessonChanged"/>
    </span>
  </div>
  <div class="result-table">
    <table>
      <tr>
        <th v-for="(column, i) in filteredTableData" :key="i">
          {{ column.item }}
        </th>
      </tr>
      <tr>
        <td v-for="(column, i) in filteredTableData" :key="i">
          {{ column.value }}
        </td>
      </tr>
    </table>
  </div>
  <div class="graph-container">
    <div class="graph-times">
      <div class="filter">
        <div class="title">期間ごとの受験回数</div>
        <div class="radio-box">
          <single-select
            :options="timeIntervalOptions"
            label="表示単位"
            class="filter"
            id="period"
            @changed="timeIntervalChanged"/>
        </div>
      </div>
      <div class="avg">Avg.{{ timeChartAvg }}</div>
      <div class="graph">
        <div class="empty" v-if="isTimeChartEmpty">
          データありませんでした
        </div>
        <div class="charts" v-else>
          <highcharts class="time-charts" :options="timeChartData" ref="chart"></highcharts>
        </div>
      </div>
    </div>
    <div class="summary">
      <div class="summary-box">
        <div class="icon"><img src="@/assets/icon-best.png"/></div>
        <div class="content">
          <div class="title lightblue">得意なLesson</div>
          <div class="text">{{ bestLesson }}</div>
        </div>
      </div>
      <div class="summary-box">
        <div class="icon"><img src="@/assets/icon-worst.png"/></div>
        <div class="content">
          <div class="title red">苦手なLesson</div>
          <div class="text">{{ worstLesson }}</div>
        </div>
      </div>
      <div class="summary-box">
        <div class="num-box">
          <div class="title red">今週のLesson受験回数</div>
          <div class="num-line">
            <div class="num">{{ weekly.lessonCurrent }}</div>
            <div class="pre">{{ weekly.lessonWow }}</div>
          </div>
        </div>
      </div>
      <div class="summary-box">
        <div class="num-box">
          <div class="title red">今週のTrial受験回数</div>
          <div class="num-line">
            <div class="num">{{ weekly.trialCurrent }}</div>
            <div class="pre">{{ weekly.trialWow }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleSelect from '@/components/atom/SingleSelect.vue'
import ReportCalendar from '@/components/molecule/ReportCalendar.vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import { mapState } from 'vuex'
import MultiSelect from '@/components/atom/MultiSelect.vue'

exportingInit(Highcharts)

export default {
  name: 'Report',
  components: {
    ReportCalendar, SingleSelect, MultiSelect,
  },
  computed: {
    ...mapState(['auth',]),
    filteredTableData() {
      const summary = this.tableData.filter(d => d.type == "Summary")
      const steps = this.tableData.filter(d => d.type == "Step" && this.selectedSteps.some(s => new RegExp(s).test(d.item)))
      const filteredSteps = this.tableData.filter(d => d.type == "Form" && this.selectedSteps.some(s => new RegExp(s).test(d.item)))
      const forms = filteredSteps.filter(d => this.selectedForms.some(s => new RegExp(s).test(d.item)))
      const filteredStepLessons = this.tableData.filter(d => d.type == "Lesson" && this.selectedSteps.some(s => new RegExp(s).test(d.item)))
      const filteredFormLessons = filteredStepLessons.filter(d => this.selectedForms.some(s => new RegExp(s).test(d.item)))
      const lessons = filteredFormLessons.filter(d => this.selectedLessons.some(s => new RegExp(s + "\\s").test(d.item)))
      return summary.concat(steps, forms, lessons)
    },
  },
  data() {
    return {
      userId: null,
      timeIntervalOptions: [
        { label: '時', value: '1' },
        { label: '日', value: '2' },
        { label: '月', value: '3' },
        { label: '年', value: '4' },
      ],
      selectedStudents:[0],
      selectedRange: {
        start: '',
        end: ''
      },
      selectedInterval: 2,
      bestLesson: '--',
      worstLesson: '--',
      timeChartAvg: 0,
      timeChartData: {
        credits: {
          enabled: false // Highcharts.comを削除
        },
        chart: {
          type: 'column',
        },
        title: {
          text: ''
        },
        plotOptions: {
          column: {
            color: '#a3a1fb',
            borderRadius: 5
          }
        },
        legend: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
        yAxis: {
          title: {
            text: null
          },
          gridLineColor: '#eaf0f4'
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
        },
        series: [
          {
            name: '受験回数',
            data: [6, 2, 3, 1, 4, 6, 5],
          },
        ],
      },
      weekly: {
        lessonCurrent: 0,
        trialCurrent: 0,
        lessonWow: '--',
        trialWow: '--',
      },
      classAchieveChart: {
        credits: {
          enabled: false // Highcharts.comを削除
        },
        chart: {
          type: 'bar',
          height: null,
        },
        title: {
          text: null,
        },
        plotOptions: {
          bar: {
            color: '#3b86ff',
            borderRadius: 3,
            pointWidth: 15,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return (this.y * 100).toFixed(2) + "%"
              }
            }
          }
        },
        legend: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
        xAxis: {
          categories: [],
          title: {
            text: null
          },
        },
        yAxis: {
          gridLineColor: 'white',
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          max: 1,
        },
        series: [{
            name: '',
            type: 'bar',
            data: []
        }],
        tooltip: {
          formatter: function() {
            return "<b>" + this.x + "</b><br>" + (this.y * 100).toFixed(2) + "%"
          }
        },
      },
      isTimeChartEmpty: true,
      tableData: [],
      stepOptions: [],
      formOptions: [],
      lessonOptions: [
        { label: 'Lesson 1', value: 'L1' },
        { label: 'Lesson 2', value: 'L2' },
        { label: 'Lesson 3', value: 'L3' },
        { label: 'Lesson 4', value: 'L4' },
        { label: 'Lesson 5', value: 'L5' },
        { label: 'Lesson 6', value: 'L6' },
        { label: 'Lesson 7', value: 'L7' },
        { label: 'Lesson 8', value: 'L8' },
        { label: 'Lesson 9', value: 'L9' },
        { label: 'Lesson 10', value: 'L10' },
        { label: 'Lesson 11', value: 'L11' },
        { label: 'Lesson 12', value: 'L12' },
        { label: 'Lesson 13', value: 'L13' },
        { label: 'Lesson 14', value: 'L14' },
        { label: 'Lesson 15', value: 'L15' },
        { label: 'Lesson 16', value: 'L16' },
        { label: 'Trial Reading', value: 'TR' },
        { label: 'Trial Listening', value: 'TL' },
        { label: 'Trial Examination', value: 'TE' },
        { label: 'ALL', value: '0' },
      ],
      selectedSteps: [0],
      selectedForms: [0],
      selectedLessons: [0],
    }
  },
  mounted() {
  },
  methods: {
    initialized(range) {
      this.userId = this.$route.query.userId != undefined ? this.$route.query.userId : this.auth.userId
      const paramStart = this.$route.query.start != undefined ? this.$route.query.start : this.dateFormat(range.start)
      const paramEnd = this.$route.query.end != undefined ? this.$route.query.end : this.dateFormat(range.end)
      if (this.$route.query.start != undefined && this.$route.query.end != undefined) {
        this.$refs.calendar.setRange(paramStart, paramEnd)
      }
      this.selectedRange = {
        start: paramStart,
        end: paramEnd,
      }
      // 先にstep情報を取得する必要がある
      this.getSteps()
      this.getTimeChart(2)
      this.getExtremeData()
      this.getWeeklyData()
      this.getTableData()
    },
    async getSteps() {
      const res = await this.$api.get('/steps')
      this.stepOptions = res.data.steps.map(s => {
        return {
          label: s.stepName,
          value: s.stepName,
        }
      })
      this.stepOptions.push({label: 'ALL', value: '0'})
      this.formOptions = res.data.steps[0].forms.map(f => {
        return {
          label: f.formName,
          value: f.formName,
        }
      })
      this.formOptions.push({label: 'ALL', value: '0'})
      // step情報を取得後、default設定を更新する
      this.selectedSteps = this.stepOptions.map(s => s.value)
      this.selectedForms = this.formOptions.map(f => f.value)
      this.selectedLessons = this.lessonOptions.map(l => l.value)
    },
    timeIntervalChanged(newVal) {
      console.log('changed:' + newVal)
      this.selectedInterval = newVal
      this.getTimeChart(this.selectedInterval)
    },
    periodChanged(newVal) {
      console.log('periodChanged:' + newVal)
      this.selectedRange = {
        start: this.dateFormat(newVal.start),
        end: this.dateFormat(newVal.end),
      }
    },
    confirmDownload() {
      this.displayDialog = true
    },
    dateFormat(date) {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}-${month}-${day}`
    },
    getTimeChart(interval) {
      this.$api.post('/report/times/range', {
        start: this.selectedRange.start,
        end: this.selectedRange.end,
        interval: interval,
        userIds: [this.userId]
      }).then(res => {
        const xAxis = res.data.map(i => i.interval)
        const values = res.data.map(i => i.value)
        this.timeChartData.xAxis.categories = xAxis
        this.timeChartData.series[0].data = values
        const sum = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        this.timeChartAvg = parseInt(values.length > 0 ? sum / values.length : 0)
        this.isTimeChartEmpty = sum == 0 ? true : false
      })
    },
    updateCharts() {
      this.getTimeChart(this.selectedInterval)
      this.getExtremeData()
      this.getWeeklyData()
      this.getTableData()
    },
    getExtremeData() {
      this.$api.post('/report/lesson/extreme', {
        start: this.selectedRange.start,
        end: this.selectedRange.end,
        userIds: [this.userId]
      }).then(res => {
        this.bestLesson = res.data.bestLesson
        this.worstLesson = res.data.worstLesson
      })
    },
    getWeeklyData() {
      this.$api.post('/report/times/weekly', {userIds: [this.userId]}).then(res => {
        this.weekly = {
          lessonCurrent: res.data.lessonCurrent,
          trialCurrent: res.data.trialCurrent,
          lessonWow: res.data.lessonWowRatio,
          trialWow: res.data.trialWowRatio,
        }
      })
    },
    getTableData() {
      this.$api.post('/report', {
        userId: this.userId,
        start: this.selectedRange.start,
        end: this.selectedRange.end,
      }).then(res => {
        this.tableData = res.data.columns
      })
    },
    stepChanged(newVal) {
      console.log('changed:' + newVal)
      this.selectedSteps = newVal
    },
    formChanged(newVal) {
      console.log('changed:' + newVal)
      this.selectedForms = newVal
    },
    lessonChanged(newVal) {
      this.selectedLessons = newVal
    },
  },
}
</script>

<style lang="scss">
.dropdown, .checkbox {
  z-index: 1;
}
.navi {
  display: flex;
  padding-top: 20px;
  height: 145px;
  box-shadow: 0px 3px 6px #00000029;

  .menus {
    flex: 3;
  }
  .title {
    flex: 3;
    font-size: 28px;
    text-align: center;
  }
  .filters {
    flex: 3;
    .filter-line {
      display: flex;
      justify-content: space-evenly;
    }
  }
  .btn-update {
    background: linear-gradient(to left, #28529D, #0E1D3A);
    color: white;
    font-weight: bold;
    padding: 5px 25px;
    border-radius: 19px;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    border: none;
  }
}
.select-info {
  display: flex;
  margin-top: 15px;
  align-items: center;
  align-items: flex-end;
  flex-direction: column;
  .label {
    border: 1px solid #1F4382;
    border-radius: 12px;
    padding: 2px 8px;
    margin: 3px 3px;
    font-weight: 600;
    display: inline-block;
  }
  .range {
    margin-right: 5%;
  }
}
.subtitle {
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  .text {
    font-size: 28px;
    text-align: left;
  }
  .filters {
    display: flex;
    min-width: 500px;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 30px;
    .lesson {
      .label {
        width: 155px;
      }
      .checkbox {
        width: 175px;
        margin-left: -96px;
      }
    }
  }
}
.result-table {
  width: 90%;
  overflow-x: scroll;
  margin: auto;
  table {
    border-collapse: collapse;
    th {
      background: #1D3F7B;
      color: white;
    }
    th, td {
      word-break: keep-all;
      border: 1px solid grey;
      padding: 5px;
      white-space: nowrap;
    }
  }
}
.graph-container {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-evenly;
  margin-top: 50px;

  .graph-times {
    flex: 5;
    padding: 10px 30px 10px 10px;
    .time-charts {
      height: 350px;
    }
    .avg {
      text-align: left;
      font-weight: bold;
      color: #a3a1fb;
    }
    .filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 16px;
      }
    }
  }
  .summary {
    flex: 2;
    .lightblue {
      color: #56D9FE;
    }
    .red {
      color: #CD1111;
    }
    .summary-box {
      display: flex;
      box-shadow: 0px 2px 6px #0000003D;
      justify-content: space-evenly;
      height: 80px;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
      .icon {
        flex: 1;
      }
      img {
        width: 50px;
        height: 50px;
      }
      .content {
        flex: 2;
        text-align: left;
      }
      .title {
        font-size: 16px;
      }
      .num-box {
        text-align: left;
        width: 80%;
        .num-line {
          display: flex;
          align-items: center;
          .num {
            font-weight: bold;
            font-size: 20px;
          }
          .pre {
            font-weight: bold;
            padding-left: 10px;
          }
        }
      }
    }
  }
}
.empty {
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>

